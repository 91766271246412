import http from "../http-common";

class TransactionDataService {
    getAll(data) {
        return http.get("/transactions", data);
    }

    getAllFromUser(config) {
        return http.get(`/transactions/user/`, config);
    }

    get(id) {
        return http.get(`/transactions/${id}`);
    }

    create(data) {
        return http.post("/transactions", data);
    }

    update(id, data) {
        return http.put(`/transactions/${id}`, data);
    }

    delete(id) {
        return http.delete(`/transactions/${id}`);
    }

    deleteAll() {
        return http.delete(`/transactions`);
    }

    getUserById(id) {
        return http.get(`/transactions/user/${id}`);
    }

    generateInvoice(data){
        return http.get(`/transactions/pdf/${data.id}/${data.filename}`, data);
    }

    checkMac(data) {
        return http.post("/transactions/checkMac", data);
    }

    generateKey(data) {
        return http.post("/transactions/generatePvrKey", data);
    }

    createOrder(){
        return http.post("/transactions/orders");
    }

    captureOrder(data, trans){
        return http.post(`/transactions/orders/${data.orderID}/capture`, trans);
    }
}

export default new TransactionDataService();
