import http from "../http-common";

class UserDataService {
    create(data) {
        return http.post("/users", data);
    }

    logIn(data) {
        return http.post("/users/login", data);
    }

    editUser(data, header) {
        return http.post("/users/edit", data, header);
    }

    deleteAccount(header){
        return http.delete("/users/deleteAccount", header);
    }

    changePassword(data, header){
        return http.post("/users/changePassword", data, header);
    }

    confirmMail(confirmationCode) {
        return http.get(`/users/confirm/${confirmationCode}`);
    }

    resetPasswordRequest(data) {
        return http.post(`/users/resetPasswordRequest`, data);
    }

    resetPassword(data) {
        return http.post(`/users/resetPassword`, data);
    }

}

export default new UserDataService();
