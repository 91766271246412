<template>
  <div id="app" class="backgroundGrey">
    <b-container
        style="background-color: #F5F5F5; max-width: 100%!important; padding-left: 0!important; padding-right: 0!important; margin-left: 0!important; margin-right: 0!important;">
      <b-row>
        <b-col class="text-left">
          <b-form-select @change="changeLanguage()" style="background-color: #F5F5F5; max-width: 180px" :id="'language'"
                         v-model="lang" size="sm"
                         :options="options"></b-form-select>
        </b-col>
        <b-col class="text-right">
          <b-link href="https://www.facebook.com/digiquesthd">
            <font-awesome-icon size="lg" style="margin-right: 15px"
                               :style="{ color: 'black' }"
                               :icon="{ prefix: 'fab', iconName: 'facebook-f'}"/>
          </b-link>
          <b-link href="https://www.instagram.com/digiquest_world/">
            <font-awesome-icon size="lg" style="margin-right: 15px"
                               :style="{ color: 'black' }"
                               :icon="{ prefix: 'fab', iconName: 'instagram'}"/>
          </b-link>
          <b-link href="https://www.youtube.com/channel/UC2n2g77vKQPsfHsnF1AylsQ/featured">
            <font-awesome-icon :style="{ color: 'black' }" style="margin-right: 30px;" size="lg"
                               :icon="{ prefix: 'fab', iconName: 'youtube'}"/>
          </b-link>
        </b-col>
      </b-row>
    </b-container>
    <b-navbar toggleable="lg" type="dark" variant="dark" style="background-color: #000!important;">
      <b-navbar-brand style="font-size: 2rem!important;">
        <router-link to="/" style="font-size: 2rem!important;" class="navbar-brand sansSerif">
          <b-img style="max-width: 180px; height: 100%; width: 100%" :src="require('./assets/LogoFrontPage.png')" fluid
                 alt="DigiquestLogo"></b-img>
        </router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="sansSerif">
          <b-nav-item class="navBarItem" v-if="role === 'Admin' && accessToken !== null">
            <router-link to="/transactions" class="nav-link navBarItem">{{ $t('nav.transactions') }}</router-link>
          </b-nav-item>
          <b-nav-item class="navBarItem" v-if="accessToken === null || accessToken === ''">
            <router-link to="/register" class="nav-link navBarItem">{{ $t('nav.register') }}</router-link>
          </b-nav-item>
          <b-nav-item-dropdown style="align-self: center;" class="navBarItem"
                               v-if="role === 'Admin' && accessToken !== null" text="BOX-TYPES" right>
            <b-dropdown-item href="/#/boxtypes">LIST</b-dropdown-item>
            <b-dropdown-item href="/#/addBoxType">ADD</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-if="accessToken === null || accessToken === ''" class="nav-item" v-b-modal.modal-login>
            <a class="nav-link navBarItem" href="#">{{ $t('nav.logIn') }}</a>
          </b-nav-item>
          <b-nav-item class="navBarItem" v-else>
            <a class="nav-link navBarItem" @click="logOut" href="#">{{ $t('nav.logout') }}</a>
          </b-nav-item>
          <b-nav-item>
            <div id="paypal-button-test"/>
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item style="color: white!important; font-size: 25px">
            <router-link to="/faq" class="nav-link navBarItem sansSerif">FAQ</router-link>
          </b-nav-item>
          <b-nav-item-dropdown style="align-self: center; color: white" class="navBarItem"
                               v-if="accessToken !== null && accessToken !== ''" :text="$t('nav.myAccount')" right>
            <b-dropdown-item href="/#/myOrders">{{ $t('nav.myOrders') }}</b-dropdown-item>
            <b-dropdown-item href="/#/myAccount">{{ $t('nav.myAccount') }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-alert v-model="passwordSuccess" variant="success" dismissible>
      {{ passwordSuccessMsg }}
    </b-alert>
    <b-container class="mt-3 mb-5 ml-0 mr-0" style="flex:1; max-width: 100%!important;">
      <router-view/>
    </b-container>

    <b-container fluid class="footer py-3 sansSerif">
      <cookie-law style="background-color: white" buttonText="I ACCEPT">
        <div slot="message">
          {{ $t('cookies') }}
        </div>
        <div slot-scope="props">
          <b-row>
            <b-col class="text-left">
              <h3>
                {{ $t('disclosure') }}
              </h3>
              <p>
                {{ $t('cookies') }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              <b-button style="background-color: #0000FF; border-radius: 0!important; color: white"
                        @click="props.accept"><span>{{ $t('iAccept') }}</span></b-button>
            </b-col>
          </b-row>
        </div>
      </cookie-law>
    </b-container>

    <b-container fluid class="footer py-3 sansSerif">
      <b-row align-v="end">
        <div class="text-left">
          <b-img :src="require('./assets/dgq-logo-footer.png')" fluid alt="Digiquest Logo"></b-img>
        </div>
        <b-row align-v="start" align-h="start" class="mt-3 mb-3">
          <b-col align-self="start">
            <p class="text-left mb-1 colorGrey">{{ $t('footer.registeredOffice') }}</p>
            <p class="text-left mb-1 colorWhite">
              Via Privata Fratelli Gabba, 5
            </p>
            <p class="text-left colorWhite">20121 Milan</p>
          </b-col>
          <b-col align-self="start">
            <p class="text-left mb-1 colorGrey">{{ $t('footer.assistanceAndSupport') }}</p>
            <p class="text-left mb-1 colorWhite">
              Tel. 06 98 57 981
            </p>
            <p class="text-left colorWhite">
              <b-link style="color: white" href="mailto:info@digiquest.it">
                info@digiquest.it
              </b-link>
            </p>
          </b-col>
          <b-col align-self="start">
            <p class="text-left mb-1 colorGrey"> {{ $t('footer.operationalHeadquarters') }}</p>
            <p class="text-left mb-1 colorWhite">
              Via Avisio, 18
            </p>
            <p class="text-left colorWhite">00048 Nettuno (RM)</p>
          </b-col>
          <b-col align-self="start">
            <p class="text-left mb-1 colorWhite">
              <b-link style="color: white" href="https://shop.digiquest.it/ecom/cms/cookies.html">
                {{ $t('footer.cookiePolicy') }}
              </b-link>
            <p class="text-left mb-1 colorWhite">
              <b-link style="color: white" href="https://shop.digiquest.it/ecom/cms/privacy.html">
                {{ $t('footer.privacyPolicy') }}
              </b-link>
            </p>
            <p class="text-left mb-1 colorWhite">
              <b-link style="color: white" href="#/termsAndConditions">
                {{ $t('terms.terms') }}
              </b-link>
            </p>
            <p class="text-left colorWhite">
            </p>
          </b-col>
          <b-col align-self="start">
            <p class="text-left mb-1 colorGrey">{{ $t('footer.followUsOn') }}</p>
            <div class="text-left">
              <b-link href="https://www.facebook.com/digiquesthd">
                <font-awesome-icon inverse size="lg" style="margin-right: 15px"
                                   :icon="{ prefix: 'fab', iconName: 'facebook-f' }"/>
              </b-link>
              <b-link href="https://www.instagram.com/digiquest_world/">
                <font-awesome-icon inverse size="lg" style="margin-right: 15px"
                                   :icon="{ prefix: 'fab', iconName: 'instagram'}"/>
              </b-link>
              <b-link href="https://www.youtube.com/channel/UC2n2g77vKQPsfHsnF1AylsQ/featured">
                <font-awesome-icon inverse size="lg" :icon="{ prefix: 'fab', iconName: 'youtube'}"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-row>
    </b-container>

    <b-modal ref="login-modal" id="modal-login" centered :title="$t('logInButton')" :ok-title="$t('logInButton')"
             @ok="logIn" :cancel-title="$t('cancel')">
      <b-row style="margin-bottom: 5px">
        <b-col sm="2">
          <label :for="'email'">E-Mail:</label>
        </b-col>
        <b-col sm="10">
          <b-form-input
              :state="emailState"
              :type="'email'"
              :id="'email'"
              required
              v-model="user.eMail"
              name="email"
              aria-describedby="email-feedback"
          />
          <b-form-invalid-feedback id="email-feedback">
            {{ $t('pleaseEnterEmail') }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 5px">
        <b-col sm="2">
          <label :for="'password'">Password:</label>
        </b-col>
        <b-col sm="10">
          <b-form-input :state="passwordState"
                        required
                        :id="'password'"
                        :type="'password'"
                        v-model="user.password"
                        aria-describedby="password-feedback">
          </b-form-input>
          <b-form-invalid-feedback id="password-feedback">
            {{ $t('pleaseEnterPassword') }}
          </b-form-invalid-feedback>
        </b-col>
        <b-col sm="12">
          <a href="#" @click="openPasswordModal" v-b-modal.modal-passwort-reset>{{ $t('passwordForgotten') }}</a>
        </b-col>
      </b-row>
      <b-alert v-model="error" variant="danger" dismissible>
        {{ errorMsg }}
      </b-alert>
    </b-modal>

    <b-modal ref="password-modal" id="modal-password-reset" centered :title="$t('passwordForgotten')"
             :ok-title="$t('resetPassword')" @ok="resetPasswordRequest" :cancel-title="$t('cancel')">
      <b-row style="margin-bottom: 5px">
        <b-col sm="2">
          <label :for="'email'">E-Mail:</label>
        </b-col>
        <b-col sm="10">
          <b-form-input
              :state="emailState"
              :type="'email'"
              :id="'email'"
              required
              v-model="user.eMail"
              name="email"
              aria-describedby="email-feedback"
          />
          <b-form-invalid-feedback id="email-feedback">
            {{ $t('pleaseEnterEmail') }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-alert v-model="errorPasswordReset" variant="danger" dismissible>
        {{ errorPasswordResetMsg }}
      </b-alert>
    </b-modal>
  </div>
</template>
<script>
import {mapState} from "vuex"
import UserDataService from "./services/UserDataService";

export default {
  name: "app",
  data() {
    return {
      lang: 'null',
      options: [
        {value: null, text: 'Select language'},
        {value: 'en', text: 'English'},
        {value: 'it', text: 'Italian'},
      ]
      ,
      passwordSuccess: false,
      passwordSuccessMsg: '',
      user: {
        id: null,
        eMail: "",
        password: "",
      },
      error: false,
      errorMsg: '',
      submitted: false,
      amount: 0.01,
      errorPasswordReset: false,
      errorPasswordResetMsg: '',
    };
  },
  computed: {
    emailState() {
      return this.user.eMail.length <= 0 && this.submitted ? false : true
    },
    passwordState() {
      return this.user.password.length <= 0 && this.submitted ? false : true
    },

    ...mapState(["accessToken", "role"]),
  },
  methods: {
    logOut() {
      console.log('logOut');
      this.$store.commit('SET_ROLE', {role: null});
      this.$store.commit('SET_ACCESS_TOKEN', {accessToken: null});
      this.$store.commit('SET_USER', {
        user: {
          id: 1,
          name: "Guest",
          zip: "",
          street: "",
          country: "",
          city: "",
          newsletter: 0,
          email: "",
        }
      });
      localStorage.removeItem('role');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      this.submitted = false;
      this.$router.push({name: 'home'});
    },

    logIn(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.submitted = true;
      if (this.passwordState && this.emailState) {
        let data = {
          email: this.user.eMail,
          password: this.user.password,
          lang: [this.$i18n.locale],
        };
        console.log(data);
        UserDataService.logIn(data)
            .then(response => {
              this.user.id = response.data.id;
              console.log(response.data);
              this.$store.commit('SET_ACCESS_TOKEN', {accessToken: response.data.accessToken});
              this.$store.commit('SET_ROLE', {role: response.data.data.role.Name});
              localStorage.role = response.data.data.role.Name;
              localStorage.accessToken = response.data.accessToken;
              console.log("EMAIL DER MAILS: " + this.user.eMail);
              let user = {
                id: response.data.data.id,
                name: response.data.data.name,
                zip: response.data.data.zip,
                city: response.data.data.city,
                country: response.data.data.country,
                street: response.data.data.street,
                newsletter: response.data.data.newsletter,
                email: this.user.eMail,
              };
              this.$store.commit('SET_USER', {user: user});
              user = JSON.stringify(user);
              localStorage.user = user;
              this.$bvModal.hide('modal-login')
              this.error = false;
              this.errorMsg = "";
              this.user.eMail = '';
              this.user.password = '';
            })
            .catch(e => {
              console.log(e);
              this.error = true;
              console.log("E RESPONSE");
              console.log(e.response);
              this.errorMsg = this.$i18n.messages[this.$i18n.locale].logInErrorMsg;
              this.errorMsg = e.response.data.message;
              if (e.response.data.message === 'Account does not exist') {
                if (this.$i18n.locale === 'en') {
                  this.errorMsg = e.response.data.message;
                } else {
                  this.errorMsg = 'L\'account non esiste'
                }
              } else if (e.response.data.message === 'Please check if your email and password match') {
                if (this.$i18n.locale === 'en') {
                  this.errorMsg = e.response.data.message;
                } else {
                  this.errorMsg = 'Per favore, controlla che e-mail e password corrispondano';
                }
              } else if (e.response.data.message === 'Please verify your e-mail!') {
                if (this.$i18n.locale === 'en') {
                  this.errorMsg = e.response.data.message;
                } else {
                  this.errorMsg = 'Per favore verifica la tua e-mail!';
                }
              } else {
                this.errorMsg = e.response.data.message;
              }
            });
      }
    },
    resetPasswordRequest(bvModalEvt) {
      bvModalEvt.preventDefault()
      let data = {
        eMail: this.user.eMail,
      }
      UserDataService.resetPasswordRequest(data)
          .then(response => {
            console.log(response);
            this.passwordSuccess = true;
            this.passwordSuccessMsg = this.$i18n.messages[this.$i18n.locale].passwordResetMsg;
            this.$refs['password-modal'].hide();
          }).catch(e => {
        console.log(e);
        this.errorPasswordReset = true;
        console.log("E RESPONSE");
        console.log(e.response);
        this.errorPasswordResetMsg = e.response.data.message;
      });
    },

    openPasswordModal() {
      this.$refs['login-modal'].hide();
      this.$refs['password-modal'].show();
    },

    changeLanguage() {
      if (this.lang === 'en') {
        this.$i18n.locale = 'en';
      } else if (this.lang === 'it') {
        this.$i18n.locale = 'it';
      }
    }
  },
  mounted() {
    console.log("MOUNTED!");
    console.log(this.$i18n);
    console.log(this.$i18n.locale);
    this.$i18n.locale = 'it';
    if (localStorage.accessToken) {
      this.$store.commit('SET_ACCESS_TOKEN', {accessToken: localStorage.accessToken});
      console.log(localStorage.accessToken);
      this.accessToken = localStorage.accessToken;
    }
    if (localStorage.user) {
      let user = JSON.parse(localStorage.user);
      this.$store.commit('SET_USER', {user: user});
      console.log(localStorage.user);
    }
    if (localStorage.role) {
      this.$store.commit('SET_ROLE', {role: localStorage.role});
      this.role = localStorage.role;
      console.log(localStorage.role);
    }
    /*function loadScript(url, callback) {
      const el = document.querySelector(`script[src="${url}"]`);
      if (!el) {
        const s = document.createElement('script');
        s.setAttribute('src', url);
        s.onload = callback;
        document.head.insertBefore(s, document.head.firstElementChild);
      }
    }

    loadScript('https://www.paypal.com/sdk/js?client-id=ARm9H8onj7LOw9Q35qC5Br-cPDeL5Ygb2-Q-6Aw32QszhWRQHyFUFj0NNOX2uHFWYr-yvHtHzpysea3d&components=buttons,funding-eligibility', () => {

      let FUNDING_SOURCES = [
        // eslint-disable-next-line no-undef
        paypal.FUNDING.PAYPAL,
      ];

      FUNDING_SOURCES.forEach(function (fundingSource) {

        // eslint-disable-next-line no-undef
        let button = paypal.Buttons({
          fundingSource: fundingSource,
          // Set up the transaction
          createOrder(data, actions) {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: '0.01',
                },
              }],
            });
          },

          // Finalize the transaction
          onApprove(data, actions) {
            return actions.order.capture().then(details => {
              // Show a success message to the buyer
              alert(`Transaction completed by ${details.payer.name.given_name}`);
            });
          },

          OnError() {
            return (err) => {
              console.error(err);
              alert("ERROR");
            }
          }
        });
        if (button.isEligible()) {
          // Render the standalone button for that funding source
          button.render('#paypal-button');
        }
      });
    });*/
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Ubuntu&display=swap');

.ubuntu {
  font-family: 'Ubuntu', sans-serif !important;
}

.sansSerif {
  font-family: 'Open Sans', sans-serif !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.footer {
  background-color: black;
  margin-top: auto !important;
  margin-bottom: 0 !important;
}

.backgroundGrey {
  background-color: #F5F5F5;
}

.colorWhite {
  color: white;
}

.navBarItem {
  color: #ffffff !important;
}

.navBarItem:hover {
  color: #A4A4A4 !important;
}

.colorGrey {
  color: #8d8d8d
}

body {
  background-color: #F5F5F5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.modal-footer .btn-primary {
  background-color: #0000FF;
  border-radius: 0;
  color: white;
}

.modal-footer .btn-primary:hover {
  background-color: #0000AA;
  border-radius: 0;
  color: white;
}

.modal-footer .btn-secondary {
  border-radius: 0;
}

.dropdown-toggle {
  color: white !important;
}

.dropdown-item {
  color: #212529 !important;
}

html {
  padding: 0;
  margin: 0;
  height: 100%;
}
</style>
