import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    //mode: "history",
    /*routes: [
        {
            path: "/",
            alias: "/tutorials",
            name: "tutorials",
            component: () => import("./components/TutorialsList")
        },
        {
            path: "/tutorials/:id",
            name: "tutorial-details",
            component: () => import("./components/Tutorial")
        },
        {
            path: "/add",
            name: "add",
            component: () => import("./components/AddTutorial")
        }
    ]*/
    routes: [
        {
            path: "/",
            alias: "/home",
            name: "home",
            component: () => import("./components/home")
        },
        {
            path: "/home/:confirmationCode",
            alias: "/home",
            name: "home",
            component: () => import("./components/home")
        },
        {
            path: "/passwordReset/:pasCode",
            alias: "/passwordReset",
            name: "passwordReset",
            component: () => import("./components/passwordReset")
        },
        {
            path: "/Q20",
            alias: "/Q20",
            name: "Q20",
            component: () => import("./components/Q20")
        },
        {
            path: "/Q90",
            alias: "/Q90",
            name: "Q90",
            component: () => import("./components/Q90")
        },
        {
            path: "/Ti9",
            alias: "/Ti9",
            name: "Ti9",
            component: () => import("./components/Q90")
        },
        {
            path: "/Maximo",
            alias: "/Maximo",
            name: "Maximo",
            component: () => import("./components/Q90")
        },
        {
            path: "/Q80",
            alias: "/Q80",
            name: "Q80",
            component: () => import("./components/Q90")
        },
        {
            path: "/pvrTicket",
            alias: "/pvr_ticket",
            name: "pvrTicket",
            props: true,
            component: () => import("./components/PvrTicket")
        },
        {
            path: "/orderOptions",
            alias: "/orderOptions",
            name: "orderOptions",
            component: () => import("./components/orderOptions")
        },
        {
            path: "/overview",
            alias: "/overview",
            name: "Overview",
            component: () => import("./components/Overview")
        },
        {
            path: "/registration",
            alias: "/registration",
            name: "registration",
            component: () => import("./components/registration")
        },
        {
            path: "/register",
            alias: "/register",
            name: "register",
            component: () => import("./components/register")
        },
        {
            path: "/pvr",
            alias: "/pvr",
            name: "pvr",
            component: () => import("./components/pvr")
        },
        {
            path: "/transactions",
            alias: "/transactions",
            name: "Transactions",
            component: () => import("./components/transactionsList")
        },
        {
            path: "/setTopBoxTypes",
            alias: "/setTopBoxTypes",
            name: "setTopBoxTypes",
            component: () => import("./components/BoxTypesList")
        },
        {
            path: "/boxTypes",
            alias: "/boxTypes",
            name: "Box Types",
            component: () => import("./components/BoxTypesList")
        },
        {
            path: "/BoxTypes/:id",
            name: "EditBoxType",
            component: () => import("./components/EditBoxType")
        },
        {
            path: "/addBoxType",
            name: "Add Box Type",
            component: () => import("./components/AddSetTopBoxType")
        },
        {
            path: "/faq",
            name: "FAQ",
            component: () => import("./components/FAQ")
        },
        {
            path: "/myOrders",
            name: "myOrders",
            component: () => import("./components/MyOrders")
        },
        {
            path: "/myAccount",
            name: "myAccount",
            component: () => import("./components/AccountOptions")
        },
        {
            path: "/termsAndConditions",
            alias: "/termsAndConditions",
            name: "TermsAndConditions",
            component: () => import("./components/TermsAndCondition")
        },
    ]
});
